<template>
  <div class="page-box">
    <div>
      <Nav></Nav>
      <HeaderStyle
        :title="title"
        :vhtml="describe"
        :backgroundUrl="backgroundUrl"
      ></HeaderStyle>
    </div>
    <div class="person-Train">
      <Title :content="content1"></Title>
      <div class="train-dec">
        <p>北京枫调理顺科技发展有限公司，一直深耕、服务于政法领域。</p>
        <p>
          我们是一家以科技支撑，通过辅助党委和政府，整合调动社会各方力量，协同参与社会治理的企业。
        </p>
        <p>
          在这个过程中，我们不仅把矛盾解决提前到风险预防，也在积极推动将稳定的治理机制沉淀为制度，有效且长效地化解纠纷，努力促进社会的安定、有序发展，促进社会文明。
        </p>
      </div>
    </div>
    <div class="com-bg-wrap">
      <!-- 发展历程 -->
      <div class="developmentPathBox">
        <div class="title">
          <div class="name">{{ content2.name }}</div>
          <div class="eg-name">{{ content2.egName }}</div>
        </div>
        <!-- <Title :content="content2" ></Title> -->
        <div class="main block">
          <!-- <img src="@/assets/about/a-bg2.png" alt="" /> -->

          <el-timeline>
            <template v-for="item in developmentPath">
              <el-timeline-item
                placement="top"
                :class="{ timeNodeYears: item.years }"
                v-if="item.type == 'title'"
              >
                <div slot="dot" class="timeNode">{{ item.years }}</div>
                <div class="timeNodeOneTitle">
                  {{ item.content }}
                </div>
              </el-timeline-item>
              <el-timeline-item placement="top" v-if="item.type == 'center'">
                <div class="contentBox">
                  <div class="time">{{ item.time }}</div>
                  <div class="content">
                    {{ item.content }}
                  </div>
                </div>
              </el-timeline-item>
            </template>
          </el-timeline>
        </div>
      </div>
      <div class="com-bg">
        <!-- 企业文化 -->
        <div>
          <Title :content="content3"></Title>
          <div style="display: flex; justify-content: center">
            <div class="part-two-content">
              <div
                class="part-two-bg"
                v-for="(item, index) in partTwo"
                :key="index"
                :class="item.bg"
              >
                <img :src="item.img" alt="" />
                <div class="part-two-title">{{ item.title }}</div>
                <span @click="goDetail(item.url)">{{ item.tetail }}</span>
                <span @click="goDetail(item.url)">{{ item.tetailNext }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div>
        <Title :content="content4"></Title>
        <div class="ditu">
          <div class="l-box">
            <div class="big-title">北京枫调理顺科技发展有限公司</div>
            <div class="title-item">
              <p class="name">公司地址：</p>
              <p class="info">北京市石景山区永引渠南路18号北明软件园A座4层</p>
            </div>
            <div class="title-item">
              <p class="name">联系电话：</p>
              <p class="info">400-1155-282</p>
            </div>
            <div class="title-item">
              <p class="name">电子邮箱：</p>
              <p class="info">public@fengtiaolishun.com</p>
            </div>
          </div>
          <!-- <img src="@/assets/about/tidu.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppFooter from "@/components/layer/AppFooter";
import SerAsk from "@/components/layer/SerAsk";
import HeaderStyle from "@/components/commom/HeaderStyle";
import Nav from "@/components/layer/HeaderNav";
import Title from "@/components/layer/OneTitle.vue";
export default {
  name: "PerSonTrain",
  metaInfo: {
    title: "枫调理顺-社会治理与纠纷解决方案商", //设置页面title
    meta: [
      {
        //设置页面meta
        name: "keyWords",
        content:
          "调解培训,调解技能,调解技巧,调解话术,商事调解,调解学院,调解课程,线上培训,业务培训,人民调解员,调解心理,调解规范,在线调解,调解法规解读,调解理论,行专调解,中国版权链,中版链,侵权监测,版权确权,数据版权确权,版权维权,在线取证,版权取证,电子存证,证据存证,版权存证",
      },
    ],
  },
  components: {
    AppFooter,
    SerAsk,
    HeaderStyle,
    Nav,
    Title,
  },
  data() {
    return {
      title: "关于我们",
      backgroundUrl: "bg",
      describe: `<p><span style="color:#d43e49">枫</span>桥经验、非诉<span style="color:#d43e49">调</span>解、证据道<span style="color:#d43e49">理</span>、和<span style="color:#d43e49">顺</span>安然<p>`,
      content1: {
        name: "枫调理顺",
        egName: "ABOUT FENTIAOLISHUN",
      },
      content2: {
        name: "发展历程",
        egName: "HISTORY",
      },
      content3: {
        name: "企业文化",
        egName: "COMPANY CULTURE",
      },
      content4: {
        name: "联系我们",
        egName: "CONTACT US",
      },
      partTwo: [
        {
          img: require("@/assets/about/a-bg4.png"),
          bg: "bg1",
          title: "定位",
          tetail: "中国领先的社会治理辅助服务与纠纷化解解决方案提供商",
          // tetailNext:'与纠纷方案商',
        },
        {
          img: require("@/assets/about/a-bg5.png"),
          title: "愿望",
          tetail:
            "成为受人尊敬的，能够解决部分社会问题的IT解决方案和专业服务提供商",
          //  tetailNext:'和云计算服务提供商',
          bg: "bg2",
        },
        {
          img: require("@/assets/about/a-bg6.png"),
          title: "使命",
          tetail:
            "通过持续创新为客户创造价值，为社会解决问题，化解社会矛盾与纠纷",
          //  tetailNext:'创造最大价值',
          bg: "bg3",
        },
        {
          img: require("@/assets/about/a-bg7.png"),
          title: "企业精神",
          tetail: "激情创业、分享包容",
          tetailNext: "务实创新、协作进取",
          bg: "bg4",
        },
        {
          img: require("@/assets/about/a-bg8.png"),
          title: "核心价值观",
          tetail: "尊重、诚信、责任",
          tetailNext: "公平、卓越",
          bg: "bg5",
        },
      ],
      developmentPath: [
        {
          type: "title",
          years: "2019",
          content: "基于区块链为核心的社会治理与纠纷解决方案商",
        },
        {
          type: "center",
          time: "9月25日",
          content: "北京枫调理顺科技发展有限公司注册成立。",
        },
        {
          type: "center",
          time: "11月5日",
          content:
            "东莞市司法局网络调解课堂开课，随后枫调理顺培训学院在山西、河北、广东、海南、吉林等地开展在线直播培训课程。",
        },
        {
          type: "title",
          years: "2020",
          content: "",
        },
        {
          type: "center",
          time: "3月13日",
          content:
            " 线上速裁金融纠纷-“至信云审”系统上线运行，探索互联网金融纠纷案的解决方案。",
        },
        {
          type: "center",
          time: "7月2日",
          content: "山西调解学院成立暨揭牌仪式。",
        },
        {
          type: "center",
          time: "8月10日",
          content:
            "腾讯区块链加速器腾讯公布全球入选32强名单，成为腾讯区块链加速器首期成员。",
        },
        {
          type: "center",
          time: "9月27日",
          content: "北京市石景山区预付式消费信用监管和服务平台上线。",
        },
        {
          type: "center",
          time: "12月23日",
          content:
            "新华网与腾讯公司、枫调理顺在北京签署协议，面向教育行业开放建立知识产权联盟——新华至信",
        },
        {
          type: "title",
          years: "2021",
          content: "",
        },
        {
          type: "center",
          time: "1月17日",
          content: "2021腾讯云启产业生态年会举办，枫调理顺荣获“年度创新奖”。",
        },
        {
          type: "center",
          time: "4月2日",
          content: "枫调理顺携“至信链”参加腾讯千帆区块链云市场发布会。",
        },
        {
          type: "center",
          time: "3月-7月",
          content:
            "青岛市崂山区，福建省晋安区，郑州市中原区，北京市大兴区、通州区等地预付监管平台城市站陆续上线。",
        },
        {
          type: "center",
          time: "11月5日",
          content:
            "成都文交所与枫调理顺公司就区块链创新应用达成战略合作，共同推动文化艺术品与衍生数字及数字内容交易行业深度发展。",
        },
        {
          type: "title",
          years: "2022",
          content: "",
        },
        {
          type: "center",
          time: "3月8日",
          content:
            "枫调理顺与阳光信保签署战略合作协议，共促预付式消费可靠发展。",
        },
        {
          type: "title",
          years: "",
          content: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.page-box {
  font-family: PingFangSC-Regular;
  background: #f8fbff;
}
.person-Train {
  height: 3.75rem;
  background-image: url("../../assets/about/a-bg3.png");
  background-size: 100% 100%;
  .train-dec {
    margin: 0 auto;
    width: 10rem;
    height: 0.77rem;
    font-family: PingFangSC-Light;
    font-size: 0.18rem;
    color: #1c1c1c;
    text-align: center;
    line-height: 0.36rem;
    padding-bottom: 1.09rem;
    p {
      font-size: 0.18rem;
      line-height: 0.36rem;
    }
  }
}
.com-bg-wrap {
  background: #fff;
}
.com-bg {
  margin: 0 auto;
  width: 14.32rem;
  .section {
    text-align: center;
    background: #fff;
  }
}
.developmentPathBox {
  width: 100%;
  height: 12.61rem;
  background-image: url("../../assets/about/developmentPath.png");
  .title {
    padding-top: 0.6rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    // border-bottom: 1px solid #DEDEDE;
    text-align: center;
    color: #fff;
    .name {
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 0.36rem;
    }
    .eg-name {
      padding: 0 0 0.4rem 0;
      letter-spacing: 0.01rem;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 0.14rem;
      color: #bfc7d1;
    }
  }
  .main {
    width: 11.62rem;
    height: 10.61rem;
    margin: 0px auto;
    color: #fff;
    margin-left: calc(50% - 4.81rem);
    .timeNodeYears {
      height: 0.7rem;
      display: flex;
      align-items: center;
      margin-top: 0.2rem;
      padding: 0rem !important;
      .timeNode {
        width: 0.88rem;
        height: 0.88rem;
        border-radius: 50%;
        background-color: #1b73ff;
        font-size: 0.32rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Medium;
        margin-top: -0.2rem;
        margin-left: 4px;
      }
      .timeNodeOneTitle {
        font-size: 0.38rem;
        line-height: 0.38rem;
        color: #ffffff;
        margin-left: 0.5rem;
        margin-top: -0.6rem;
        font-family: PingFangSC-Medium;
      }
    }
    .contentBox {
      color: #fff;
      display: flex;
      margin-top: -0.1rem;
      font-family: PingFangSC-Medium;
        font-size: 0.18rem;
        line-height: 0.25rem;

      .time {
        width: 0.8rem;
        margin: 0rem 0.35rem 0rem 0.19rem;
        font-family: PingFangSC-Medium;
      }
    }

    /deep/.el-timeline-item__dot {
      margin-top: -0.44rem;
      margin-left: -0.44rem;
    }
    /deep/.el-timeline-item {
      padding-bottom: 0.28rem;
    }
  }
}
.htitle {
  font-size: 0.24rem;
  color: #1c1c1c;
  padding: 0.4rem 0;
}
.img-box {
  text-align: center;
  // width: 1000px;
  width: 14.32rem;
  margin: 0 auto;
  img {
    // width:14.40rem;
    width: 100%;
    height: auto;
    // height:12.16rem;
  }
}
.l-box {
  width: 14.32rem;
  margin: 0 auto;
  background-image: url("../../assets/about/tidu.png");
  background-size: 100% 4.2rem;

  .detail {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 0.16rem;
    color: #3a4350;
    line-height: 0.36rem;
  }
  .big-title {
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    font-size: 0.28rem;
    color: #000000;
    line-height: 0.28rem;
    padding-bottom: 0.4rem;
    padding-top: 0.6rem;
  }
  .title-item {
    padding-bottom: 0.32rem;
    .name {
      font-family: PingFangSC-Regular;
      font-size: 0.16rem;
      color: #8c99aa;
      line-height: 0.24rem;
      padding-bottom: 0.12rem;
    }
    .info {
      font-family: Helvetica;
      font-size: 0.18rem;
      color: #1c1c1c;
    }
  }
}
.part-two-content {
  width: 14.32rem;
  min-width: 14.32rem;
  display: flex;
  justify-content: space-around;
  .part-two-bg {
    width: 2.88rem;
    height: 4rem;
    box-sizing: border-box;
    padding: 0.77rem 0 0.31rem 0.2rem;

    span {
      display: block;
      width: 2.13rem;
      line-height: 24px;
      font-size: 0.18rem;
    }
    img {
      width: 1.06rem;
      height: 1.06rem;
    }
    .part-two-img {
      width: 1.13rem;
      height: 0.86rem;
      margin-top: 0.58rem;
    }
    .part-two-title {
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      font-size: 0.28rem;
      padding-bottom: 0.16rem;
    }
    .line {
      margin-top: 0.08rem;
      width: 0.53rem;
      height: 0.04rem;
      background: #3478e3;
    }
    .part-two-detail {
      margin-top: 0.22rem;
      font-size: 0.14rem;
      color: #354657;
      width: 2.06rem;
      height: 0.54rem;
      font-weight: 400;
      .t1 {
        color: #354657;
        font-weight: bold;
      }
    }

    &:nth-of-type(2) {
      span {
        width: 2.05rem;
      }
    }
    &:nth-of-type(4) {
      span {
        width: 1.78rem;
      }
    }
    &:nth-of-type(5) {
      span {
        width: 2.19rem;
      }
    }
  }
}
.bg {
  background-image: url("../../assets/about/a-bg1.png");
  background-size: 100% 100%;
}
.bg1,
.bg3,
.bg5 {
  background-image: linear-gradient(134deg, #136eff 1%, #448cff 100%);
  color: #fff;
}
.bg2,
.bg4 {
  background: #f1f6ff;
  color: #1c1c1c;
}
.ditu {
  height: 4.2rem;
  background: #f7faff;
  // background-image: url("../../assets/about/tidu.png");
  // background-size: 100% 4.2rem;
}
</style>
